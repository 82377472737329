import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prices = _resolveComponent("Prices", true)!
  const _component_DisplayPrices = _resolveComponent("DisplayPrices")!
  const _component_Flex = _resolveComponent("Flex")!

  return (_openBlock(), _createBlock(_component_Flex, {
    justifyContent: "space-between",
    wrap: "wrap"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Prices, {
        form: _ctx.form,
        title: _ctx.prices.title,
        description: _ctx.prices.description,
        input: _ctx.prices.input,
        button: _ctx.prices.button,
        "onUpdate:form": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.numberCollab = $event))
      }, null, 8, ["form", "title", "description", "input", "button"]),
      _createVNode(_component_DisplayPrices, {
        form: _ctx.form,
        firstLabel: _ctx.display.firstLabel,
        secondLabel: _ctx.display.secondLabel,
        thirdLabel: _ctx.display.thirdLabel
      }, null, 8, ["form", "firstLabel", "secondLabel", "thirdLabel"])
    ]),
    _: 1
  }))
}