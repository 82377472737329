import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dae1b18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prices = _resolveComponent("Prices", true)!
  const _component_Container = _resolveComponent("Container")!
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Container, {
      class: "container-form",
      size: "medium"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Prices, {
          prices: _ctx.prices,
          display: _ctx.display
        }, null, 8, ["prices", "display"])
      ]),
      _: 1
    }),
    (_ctx.block?.InfoTitle || _ctx.block?.InfoText)
      ? (_openBlock(), _createBlock(_component_Container, {
          key: 0,
          class: "container-info",
          size: "medium"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Text, {
              value: _ctx.block?.InfoText
            }, null, 8, ["value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}