
import { computed, defineComponent, PropType } from "vue";

//Layouts
import Separator from "../layouts/Separator.vue";

//Atoms
import Text from "../atoms/Text.vue";

export default defineComponent({
  components: { Text, Separator },
  props: {
    form: {
      type: Object as PropType<{ numberCollab: number }>,
    },
    firstLabel: {
      type: String,
    },
    secondLabel: {
      type: String,
    },
    thirdLabel: {
      type: String,
    },
  },
  setup(props) {
    const listCost = [
      {
        minEmploye: 1,
        maxEmploye: 50,
        cost: 5000,
        fee: 7500,
      },
      {
        minEmploye: 50,
        maxEmploye: 200,
        cost: 38,
        fee: 8000,
      },
      {
        minEmploye: 200,
        maxEmploye: 350,
        cost: 36,
        fee: 9000,
      },
      {
        minEmploye: 350,
        maxEmploye: 500,
        cost: 34,
        fee: 10000,
      },
      {
        minEmploye: 500,
        maxEmploye: 750,
        cost: 32,
        fee: 12000,
      },
      {
        minEmploye: 750,
        maxEmploye: 1000,
        cost: 30,
        fee: 15000,
      },
      {
        minEmploye: 1000,
        maxEmploye: 1250,
        cost: 28,
        fee: 17500,
      },
      {
        minEmploye: 1250,
        maxEmploye: 1000000000000,
        cost: 26,
        fee: 20000,
      },
    ];

    const cost = computed(() => {
      let numberCollab = props.form?.numberCollab ?? 0;

      let price = 0;

      let listCostOrderDesc = listCost.map((x) => x);
      listCostOrderDesc.sort((a, b) => b.minEmploye - a.minEmploye);

      listCostOrderDesc.forEach((cost) => {
        if (
          cost.minEmploye <= numberCollab &&
          cost.maxEmploye >= numberCollab
        ) {
          // Si le nombre total d'employés ne dépasse pas la première tranche (prix fixe)
          if (numberCollab <= listCost[0].maxEmploye) {
            price += listCost[0].cost;
            numberCollab = 0;
          } else {
            // Calcule le nombre d'employés dans la plage de coût actuelle
            let numberOfEmployeInTranche = numberCollab - cost.minEmploye;

            // Ajoute le coût pour les employés de cette plage à 'price'
            price += numberOfEmployeInTranche * cost.cost;

            numberCollab -= numberOfEmployeInTranche;
          }
        }
      });

      return price;
    });

    const fee = computed(() => {
      let numberCollab = props.form?.numberCollab ?? 0;

      let cost = listCost.find((item) => {
        return (
          item.minEmploye <= numberCollab && item.maxEmploye >= numberCollab
        );
      });

      return cost?.fee ?? 0;
    });

    return { cost, fee };
  },
});
