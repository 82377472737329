
import { ref, defineComponent, onMounted, PropType } from "vue";

//Layouts

//Atoms
import Title from "@/components/atoms/Title.vue";
import Text from "@/components/atoms/Text.vue";
import Input from "@/components/atoms/form/Input.vue";
import Button from "@/components/atoms/Button.vue";

export default defineComponent({
  components: { Title, Text, Input, Button },
  props: {
    form: {
      type: Object as PropType<{ numberCollab: number }>,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    input: {
      type: Object as PropType<{
        label: string;
        placeholder: string;
      }>,
    },
    button: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref<undefined | number>(undefined);

    const onSubmit = () => emit("update:form", inputValue.value);

    onMounted(() => (inputValue.value = props.form?.numberCollab));

    return { inputValue, onSubmit };
  },
});
