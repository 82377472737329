import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c871da3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prices" }
const _hoisted_2 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Text = _resolveComponent("Text")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      value: _ctx.title,
      size: "medium"
    }, null, 8, ["value"]),
    _createVNode(_component_Text, {
      value: _ctx.description,
      size: "large"
    }, null, 8, ["value"]),
    _createVNode(_component_Input, {
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      label: _ctx.input.label,
      placeholder: _ctx.input.placeholder,
      type: "number",
      color: "dark"
    }, null, 8, ["modelValue", "label", "placeholder"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        onClick: _ctx.onSubmit,
        value: _ctx.button,
        gradient: true,
        color: "light"
      }, null, 8, ["onClick", "value"])
    ])
  ]))
}