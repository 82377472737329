import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "prices" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Jumbortron = _resolveComponent("Jumbortron")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Prices = _resolveComponent("Prices", true)!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Jumbortron, {
      title: _ctx.data?.Hero?.Title,
      full: true,
      background: 
        _ctx.data ? _ctx.backendUrl + _ctx.data?.Hero?.Background.data.attributes.url : ''
      ,
      scrollTo: {
        id: 'next',
        img: _ctx.data ? _ctx.backendUrl + _ctx.data?.Hero?.Arrow.data.attributes.url : '',
      }
    }, null, 8, ["title", "background", "scrollTo"]),
    _createVNode(_component_Paragraph, {
      id: "next",
      block: 
        _ctx.data?.Blocks?.find((block) => block.__component === 'blocks.paragraph')
      
    }, null, 8, ["block"]),
    (_ctx.data)
      ? (_openBlock(), _createBlock(_component_Prices, {
          key: 0,
          block: 
        _ctx.data?.Blocks?.find((block) => block.__component === 'blocks.prices')
      
        }, null, 8, ["block"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Contact)
  ]))
}