
import { defineComponent, ref } from "vue";

//Layouts
import Container from "@/components/layouts/Container.vue";

//Atoms
import Text from "@/components/atoms/Text.vue";

//Organismes
import Prices from "@/components/organismes/Prices.vue";

export default defineComponent({
  components: { Container, Text, Prices },
  props: {
    block: {
      type: Object,
    },
  },
  setup(props) {
    const prices = ref({
      title: props.block?.Title ?? "",
      description: props.block?.Text ?? "" ?? "",
      input: {
        label: props.block?.Input.Label ?? "",
        placeholder: props.block?.Input.Placeholder ?? "",
      },
      button: props.block?.Button.Text ?? "",
    });

    const display = ref({
      firstLabel: props.block?.FirstText ?? "",
      secondLabel: props.block?.SecondText ?? "",
      thirdLabel: props.block?.ThirdText ?? "",
    });

    return { prices, display };
  },
});
