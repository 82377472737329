
import { defineComponent, PropType, ref } from "vue";

//Layouts
import Flex from "@/components/layouts/Flex.vue";

//Molecules
import Prices from "@/components/molecules/form/Prices.vue";
import DisplayPrices from "@/components/molecules/DisplayPrices.vue";

export default defineComponent({
  components: { Flex, Prices, DisplayPrices },
  props: {
    prices: {
      type: Object as PropType<{
        title: string;
        description: string;
        input: {
          label: string;
          placeholder: string;
        };
        button: string;
      }>,
    },
    display: {
      type: Object as PropType<{
        firstLabel: string;
        secondLabel: string;
        thirdLabel: string;
      }>,
    },
  },
  setup() {
    const form = ref({ numberCollab: null });

    return { form };
  },
});
