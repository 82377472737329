import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9e88cae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "display-prices" }
const _hoisted_2 = { class: "collab" }
const _hoisted_3 = { class: "price" }
const _hoisted_4 = { class: "fee" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_Separator = _resolveComponent("Separator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Text, {
        value: `${_ctx.form?.numberCollab || '000'}`,
        size: "xlarge"
      }, null, 8, ["value"]),
      _createVNode(_component_Separator, { color: "light" }),
      _createVNode(_component_Text, {
        value: _ctx.firstLabel,
        size: "small"
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Text, {
        value: String('CHF ' + _ctx.cost),
        size: "large"
      }, null, 8, ["value"]),
      _createVNode(_component_Separator, { color: "light" }),
      _createVNode(_component_Text, {
        value: _ctx.secondLabel,
        size: "small"
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Text, {
        value: String('CHF ' + _ctx.fee),
        size: "large"
      }, null, 8, ["value"]),
      _createVNode(_component_Separator, { color: "light" }),
      _createVNode(_component_Text, {
        value: _ctx.thirdLabel,
        size: "small"
      }, null, 8, ["value"])
    ])
  ]))
}